import {saveAs} from 'file-saver'
import ExcelJS from 'exceljs'
import {useEffect, useState} from 'react'
import Importcsv from '../Importcsv'
import {Addvendors} from '../Addvendors'
import {useAuth} from '../../../../modules/auth'
import {collection, doc, onSnapshot} from 'firebase/firestore'
import db from '../../../../../services/firebase'
import {toast} from 'react-toastify'

const VendorListToolbar = () => {
  const {currentUser} = useAuth()
  const [isDropdownVisible, setDropdownVisible] = useState(false)
  const [isCSV, setIsCSV] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [vendor, setVendor] = useState<any[]>([])

  useEffect(() => {
    getVendor()
  }, [])

  const getVendor = async () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'vendors')

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const risksData = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
            archived: doc.data().archived,
          }))
          .filter((vendor) => !vendor.archived) // Filter out archived vendors
        setVendor(risksData)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
    }
  }

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible)
  }
  const handleMannually = () => {
    setIsCSV(!isCSV)
  }

  const handleOpenModal2 = () => {
    setIsCSV(true)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  const exportData = async () => {
    if (vendor.length > 0) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('Vendor Risks')

      worksheet.columns = [
        {header: 'Vendor Name', key: 'vendor_name', width: 30},
        {header: 'Category', key: 'category', width: 20},
        {header: 'Website URL', key: 'website_url', width: 30},
        {header: 'Policy URL', key: 'policy_url', width: 30},
        {header: 'Terms URL', key: 'terms_url', width: 30},
        {header: 'Reliance', key: 'reliance', width: 15},
        {header: 'Risk Level', key: 'risk_level', width: 15},
        {header: 'Authentication Type', key: 'authentication_type', width: 20},
        // {header: 'Created At', key: 'created_at', width: 25},
        {header: 'Diligence File Name', key: 'diligence_file_name', width: 30},
        // {header: 'Diligence URL', key: 'diligence_url', width: 30},
        // {header: 'Upload Time', key: 'upload_time', width: 20},
        // {header: 'Uploaded By', key: 'uploaded_by', width: 20},
        {header: 'Due Diligence Status', key: 'due_diligence_status', width: 20},
        {header: 'Due Diligence Date', key: 'due_diligence_date', width: 20},
        {header: 'Skip Next Due Diligence', key: 'skip_next_due_diligence', width: 25},
        {header: 'Two Factor Authentication', key: 'two_factor', width: 25},
        {header: 'Impact Assessment', key: 'impact_assessment', width: 50},
        // {header: 'Last Updated', key: 'last_updated', width: 25},
        // {header: 'Logo Name', key: 'logo_name', width: 25},
        // {header: 'Logo URL', key: 'logo_url', width: 30},
        {header: 'Owner', key: 'owner', width: 20},
      ]

      const headerRow = worksheet.getRow(1)
      headerRow.font = {bold: true, color: {argb: 'FFFFFFFF'}, size: 10}
      headerRow.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: {argb: 'FF000000'},
      }
      headerRow.height = 30
      headerRow.alignment = {horizontal: 'center', vertical: 'middle'}
      worksheet.views = [{state: 'frozen', ySplit: 1}]

      const exportData = vendor.map((row) => ({
        vendor_name: row.vendor_name || '',
        category: row.category || '',
        website_url: row.website_url || '',
        policy_url: row.policy_url || '',
        terms_url: row.terms_url || '',

        reliance: row.reliance || '',
        risk_level: row.risk_level || '',
        authentication_type: row.authentication_type || '',
        // created_at: row.created_at
        //   ? new Date(row.created_at.seconds * 1000).toLocaleDateString()
        //   : '',
        diligence_file_name: row.diligence?.[0]?.diligence_file_name || '',
        // diligence_url: row.diligence?.[0]?.diligence_url || '',
        // upload_time: row.diligence?.[0]?.upload_time || '',
        // uploaded_by: row.diligence?.[0]?.uploaded_by || '',
        due_diligence_status: row.due_diligence_status || '',
        due_diligence_date: row.due_diligence_date || '',
        skip_next_due_diligence: row.skip_next_due_diligence ? 'Yes' : 'No',
        two_factor: row.two_factor ? 'Yes' : 'No',
        impact_assessment: row.impact_assessment?.join(', ') || '',
        // last_updated: row.last_updated
        //   ? new Date(row.last_updated.seconds * 1000).toLocaleDateString()
        //   : '',
        // logo_name: row.logo?.[0]?.name || '',
        // logo_url: row.logo?.[0]?.url || '',
        owner: row.owner || '',
      }))

      exportData.forEach((item) => {
        const row = worksheet.addRow(item)
        row.height = 20
        row.font = {size: 10}

        if (item.reliance === 'High') {
          row.getCell('F').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F4CCCC'},
          }
        } else if (item.reliance === 'Medium') {
          row.getCell('F').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFF2CC'},
          }
        } else if (item.reliance === 'Low') {
          row.getCell('F').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'D9EAD3'},
          }
        }

        // Adjusting the fill color for risk level in column G
        if (item.risk_level === 'High') {
          row.getCell('G').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F4CCCC'},
          }
        } else if (item.risk_level === 'Medium') {
          row.getCell('G').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'FFF2CC'},
          }
        } else if (item.risk_level === 'Low') {
          row.getCell('G').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'D9EAD3'},
          }
        }

        if (item.due_diligence_status === 'Incomplete') {
          row.getCell('J').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'F4CCCC'},
          }
        } else if (item.due_diligence_status === 'Complete') {
          row.getCell('J').fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: {argb: 'D9EAD3'},
          }
        }
      })

      const buffer = await workbook.xlsx.writeBuffer()
      const excelBlob = new Blob([buffer], {type: 'application/octet-stream'})

      // Save the file
      saveAs(excelBlob, `VendorRisks.xlsx`)

      toast.success('Vendor risks data exported successfully', {
        position: toast.POSITION.TOP_CENTER,
        className: 'bg-white',
        style: {
          borderRadius: '8px',
          boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          border: '1px solid #e0e0e0',
          color: 'black',
        },
        progressStyle: {
          background: '#1CBB8D',
        },
        icon: ({theme, type}) => (
          <svg
            width='26'
            height='26'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            style={{color: '#1CBB8D'}}
          >
            <path
              d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
              fill='currentColor'
            />
          </svg>
        ),
        autoClose: 3000,
      })
    }
  }

  return (
    // <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>

    <>
      <Addvendors isOpen={isModalOpen} onClose={handleCloseModal} />

      <Importcsv isOpen={isCSV} onClose={handleMannually} />
      <button
        type='button'
        className=' btn btn-sm btn-light me-4 fs-6'
        onClick={exportData}
        style={{height: '40px', background: '#fff', color: '#000', border: '2px solid #C5C5C5'}}
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='18'
          height='18'
          viewBox='0 0 24 24'
          fill='none'
          stroke='#5A5A5A'
          stroke-width='2'
          stroke-linecap='round'
          stroke-linejoin='round'
          className='icon icon-tabler icons-tabler-outline icon-tabler-download mb-1'
          style={{marginRight: '4px'}}
        >
          <path stroke='none' d='M0 0h24v24H0z' fill='none' />
          <path d='M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2' />
          <path d='M7 11l5 5l5 -5' />
          <path d='M12 4l0 12' />
        </svg>
        Export
      </button>

      <div className='dropdown'>
        {currentUser?.role !== 'Auditor' && (
          <button
            className='btn btn-sm fs-6 rotate'
            style={{background: '#1CBB8D', height: '40px', color: '#fff', width: '150px'}}
            onMouseEnter={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#148363')
            }
            onMouseLeave={(e: React.MouseEvent<HTMLElement>) =>
              (e.currentTarget.style.backgroundColor = '#1CBB8D')
            }
            onClick={toggleDropdown}
            id='dropdownMenu2'
            data-bs-toggle='dropdown'
            aria-haspopup='true'
            aria-expanded='false'
          >
            {/* <svg
            xmlns='http://www.w3.org/2000/svg'
            width='20'
            height='20'
            viewBox='0 0 24 24'
            fill='none'
            stroke='currentColor'
            stroke-width='2'
            stroke-linecap='round'
            stroke-linejoin='round'
            className='icon icon-tabler icons-tabler-outline icon-tabler-plus'
          >
            <path stroke='none' d='M0 0h24v24H0z' fill='none' />
            <path d='M12 5l0 14' />
            <path d='M5 12l14 0' />
          </svg> */}
            <span className='mx-2'>Add Vendor</span>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              viewBox='0 0 24 24'
              fill='none'
              stroke='currentColor'
              stroke-width='2'
              stroke-linecap='round'
              stroke-linejoin='round'
              className='icon icon-tabler icons-tabler-outline icon-tabler-chevron-down rotate-180'
            >
              <path stroke='none' d='M0 0h24v24H0z' fill='none' />
              <path d='M6 9l6 6l6 -6' />
            </svg>
          </button>
        )}
        <ul className='dropdown-menu mt-2' aria-labelledby='dropdownMenu2' style={{width: '150px'}}>
          <li>
            <button
              className='dropdown-item text-hover-primary button-with-icon'
              type='button'
              onClick={handleOpenModal}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-pencil mb-1 mx-1 '
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M4 20h4l10.5 -10.5a2.828 2.828 0 1 0 -4 -4l-10.5 10.5v4' />
                <path d='M13.5 6.5l4 4' />
              </svg>{' '}
              Add manually
            </button>
          </li>

          <li>
            <button
              className='dropdown-item text-hover-primary button-with-icon'
              type='button'
              onClick={handleOpenModal2}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='18'
                height='18'
                viewBox='0 0 24 24'
                fill='none'
                stroke='#5A5A5A'
                stroke-width='2'
                stroke-linecap='round'
                stroke-linejoin='round'
                className='icon icon-tabler icons-tabler-outline icon-tabler-file-import mb-1 mx-1'
              >
                <path stroke='none' d='M0 0h24v24H0z' fill='none' />
                <path d='M14 3v4a1 1 0 0 0 1 1h4' />
                <path d='M5 13v-8a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-5.5m-9.5 -2h7m-3 -3l3 3l-3 3' />
              </svg>{' '}
              Import
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}

export {VendorListToolbar}
