/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {KTIcon, toAbsoluteUrl} from '../../../_metronic/helpers'
import db from '../../../services/firebase'
import {useAuth} from '../../modules/auth'
import {AssetListToolbar} from './components/header/AssetListToolbar'
import Swal from 'sweetalert2'
import {debounce} from 'lodash'
import {AssetListLoading} from './components/loading/AssetListLoading'
import {Helmet} from 'react-helmet'
import {EditAssetModel} from './components/EditAssetModal'
import {addDoc, collection, deleteDoc, doc, onSnapshot, serverTimestamp} from 'firebase/firestore'
import {format, parse} from 'date-fns'
import {toast} from 'react-toastify'

type Props = {
  className: string
  blogs?: any
  getAssetID: any
  onDataCountChange: any
}

const AssetManagement: React.FC<Props> = ({getAssetID, onDataCountChange}) => {
  const [assets, setAssets] = useState<any[]>([])
  const {currentUser} = useAuth()
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [searchLength, setSearchLength] = useState(false)
  const [showEmptyState, setShowEmptyState] = useState<boolean>(false)
  const [assetID, setAssetID] = useState('')
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const [sortBy, setSortBy] = useState('name')
  const [sortDirection, setSortDirection] = useState('asc')
  
  useEffect(() => {
    // Delay the rendering of the empty state for 3 seconds
    const timeoutId = setTimeout(() => {
      setShowEmptyState(true)
    }, 2000)
    return () => clearTimeout(timeoutId)
  }, [])

  useEffect(() => {
    getAsset()
  }, [])

  useEffect(() => {
    setFilteredItems(assets)
  }, [assets])

  // Function to handle sorting when a table header is clicked
  const handleSort = (column) => {
    const newSortDirection = column === sortBy && sortDirection === 'asc' ? 'desc' : 'asc'
    setSortBy(column)
    setSortDirection(newSortDirection)

    // Perform sorting
    const sortedItems = [...filteredItems].sort((a, b) => {
      if (column === 'name' || column === 'os_version' || column === 'owner') {
        // Example sorting logic for string fields
        return newSortDirection === 'asc'
          ? a[column].localeCompare(b[column])
          : b[column].localeCompare(a[column])
      } else if (column === 'last_checkedin') {
        // Example sorting logic for date fields
        const dateA = new Date(a[column]).getTime()
        const dateB = new Date(b[column]).getTime()
        return newSortDirection === 'asc' ? dateA - dateB : dateB - dateA
      } else {
        // Handle other column types as needed
        return 0
      }
    })

    setFilteredItems(sortedItems)
  }

  const getAsset = () => {
    try {
      const tenantId = currentUser?.tenantId || ''
      const tenantRef = doc(db, 'tenants', tenantId)
      const PolicyRef = collection(tenantRef, 'assets')

      // const limitedQuery = query(controlsCollectionRef, limit(10))

      const unsubscribe = onSnapshot(PolicyRef, (snapshot) => {
        const assetData = snapshot.docs.map((item) => ({
          ...item.data(),
          id: item.id,
        }))
        setAssets(assetData)
        const newDataCount = assetData.length
        onDataCountChange(newDataCount)
        setShowEmptyState(false)
      })

      return unsubscribe
    } catch (error) {
      console.error('Error getting controls:', error)
      setShowEmptyState(false)
    }
  }

  const handleOpenModal2 = (id) => {
    setIsModalOpen2(true)
    setAssetID(id)
  }

  const handleCloseModal2 = () => {
    setIsModalOpen2(false)
  }

  const deleteHandler = async (assetData) => {

    try {
      const result = await Swal.fire({
        icon: 'warning',
        title: `Remove ${assetData.name} Asset`,
        text: `Are you sure you want to remove ${assetData.name} asset ? This action cannot be undone.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, Remove',
        cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-secondary',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        const tenantId = currentUser?.tenantId || ''
        const tenantRef = doc(db, 'tenants', tenantId)
        const policyRef = collection(tenantRef, 'assets')
        const docRef = doc(policyRef, assetData.id)
        await deleteDoc(docRef)
        getAsset()

        toast.success('Asset has been deleted successfully!', {
          position: toast.POSITION.TOP_CENTER,
          className: 'bg-white',
          style: {
            borderRadius: '8px',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            color: 'black',
          },
          progressStyle: {
            background: '#1CBB8D',
          },
          icon: ({theme, type}) => (
            <svg
              width='26'
              height='26'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              style={{color: '#1CBB8D'}}
            >
              <path
                d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
                fill='currentColor'
              />
            </svg>
          ),
          autoClose: 3000,
        })

        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth() + 1 
        const day = currentDate.getDate()

        const todaysDate = month + '/' + day + '/' + year

        const activitiesRef = collection(tenantRef, 'activities')
        await addDoc(activitiesRef, {
          message: `${currentUser?.userName} deleted asset '${assetData.name}'`,
          timestamp: serverTimestamp(),
          tenantId: currentUser?.tenantId,
          uid: currentUser?.uid,
          action: 'Asset Added',
          target: 'Asset Management',
          email: currentUser?.email,
          username: currentUser?.userName,
          date: todaysDate,
        })
        Swal.fire({
          icon: 'success',
          title: 'Deleted!',
          text: ` "${assetData.name}" has been deleted.`,
          customClass: {
            confirmButton: 'btn btn-success',
          },
          buttonsStyling: false,
        })
      }
    } catch (error) {
      console.error('Error deleting asset', error)
      // Handle the error or show an error message to the user
      Swal.fire({
        icon: 'error',
        title: 'Error!',
        text: 'An error occurred while deleting the asset.',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
    } finally {
      // setDropdownVisible(false)
    }
  }

  const debouncedHandleInputChange = debounce((searchTerm: string) => {
    if (assets !== null) {
      // Check if assets is not null
      if (searchTerm === '') {
        // If the search term is empty, reset to the original assets
        setFilteredItems(assets)
        setSearchLength(false)
      } else {
        // const filteredItems = assets.filter((user) => user.name.toLowerCase().includes(searchTerm))
        const filteredItems = assets.filter((t) => {
          const taskName = typeof t.name === 'string' ? t.name.toLowerCase() : ''
          return taskName.includes(searchTerm)
        })
        setFilteredItems(filteredItems)
        setSearchLength(filteredItems.length === 0)
      }
    }
  }, 300)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value.toLowerCase()
    setSearchTerm(searchTerm)
    debouncedHandleInputChange(searchTerm) // Invoke the debounced function with the search term
  }

  const formatTimestamp = (dateInput) => {
    if (typeof dateInput === 'string') {
      // Handle ISO 8601 date string (e.g., "2024-07-31")
      try {
        const date = parse(dateInput, 'yyyy-MM-dd', new Date())
        return format(date, 'd MMMM, yyyy')
      } catch (error) {
        return 'Invalid Date'
      }
    } else if (dateInput && dateInput.seconds) {
      // Handle Firestore timestamp
      return format(new Date(dateInput.seconds * 1000), 'd MMMM, yyyy')
    } else if (dateInput instanceof Date) {
      // Handle JavaScript Date object
      return format(dateInput, 'd MMMM, yyyy')
    } else {
      return 'Invalid Date'
    }
  }

  return (
    <>
      <Helmet>
        <title>Secureslate | Asset Management</title>
      </Helmet>
      <div className='card-header border-0 pt-6 h-100'>
        <div className='card-title'>
          {/* begin::Search */}
          <div className='d-flex align-items-center position-relative my-1'>
            <KTIcon iconName='magnifier' className='fs-1 position-absolute ms-6' />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control  w-250px ps-14'
              placeholder='Search Asset..'
              value={searchTerm}
              onChange={handleInputChange}
            />
          </div>
          {/* end::Search */}
        </div>
        <div className='card-toolbar'>
          {/* begin::Group actions */}
          <AssetListToolbar />
          {/* end::Group actions */}
        </div>
      </div>
      <div className='card-body py-3 '>
        {/* begin::Table container */}
        <div className='table-responsive' style={{minHeight: '60vh', overflowY: 'auto'}}>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold ' style={{color:"#5A5A5A"}}>
                <th className='min-w-150px' onClick={() => handleSort('name')}>
                  Name{' '}
                  {sortBy === 'name' && (
                    <span
                      className={`ms-1 icon-xxs text-muted ${
                        sortDirection === 'asc' ? 'bi bi-arrow-up' : 'bi bi-arrow-down'
                      }`}
                    />
                  )}
                </th>
                <th className='min-w-100px' onClick={() => handleSort('os_version')}>
                  OS Version{' '}
                  {sortBy === 'os_version' && (
                    <span
                      className={`ms-1 icon-xxs text-muted ${
                        sortDirection === 'asc' ? 'bi bi-arrow-up' : 'bi bi-arrow-down'
                      }`}
                    />
                  )}
                </th>
                <th className='min-w-150px' onClick={() => handleSort('owner')}>
                  Owner{' '}
                  {sortBy === 'owner' && (
                    <span
                      className={`ms-1 icon-xxs text-muted ${
                        sortDirection === 'asc' ? 'bi bi-arrow-up' : 'bi bi-arrow-down'
                      }`}
                    />
                  )}
                </th>
                <th className='min-w-100px' onClick={() => handleSort('last_checkedin')}>
                  Last Checked in{' '}
                  {sortBy === 'last_checkedin' && (
                    <span
                      className={`ms-1 icon-xxs text-muted ${
                        sortDirection === 'asc' ? 'bi bi-arrow-up' : 'bi bi-arrow-down'
                      }`}
                    />
                  )}
                </th>
                <th className='min-w-100px'>HD Encryption</th>
                <th className='min-w-100px'>Anti-virus</th>
                <th className='min-w-50px'>Password Policy</th>
                <th className='min-w-50px'>Screen Policy</th>
                <th className='min-w-50px'>Firewall</th>
                <th className='min-w-50px'>In Audit Scope</th>
                <th className='min-w-100px'></th>
              </tr>
            </thead>
            <EditAssetModel id={assetID} isOpen={isModalOpen2} onClose={handleCloseModal2} />
            {/* end::Table head */}
            {/* begin::Table body */}
            {filteredItems && filteredItems.length > 0 ? (
              filteredItems
                .filter((data) => data.is_repo !== true)
                .map((assetsData) => {
                  return (
                    <tbody>
                      <tr key={assetsData.id}>
                        <td>
                          <a href='#' className='text-dark fw-bold text-hover-primary d-block fs-6'  onClick={() => handleOpenModal2(assetsData.id)}>
                            {assetsData.name}
                          </a>
                        </td>
                        <td>
                          <a href='#' className='text-dark fw-bold d-block fs-6'>
                            {assetsData.os_version}
                          </a>
                          <span className='fw-semibold text-muted d-block fs-7'>
                            {assetsData.model}
                          </span>
                        </td>
                        <td>
                          <div className='d-flex align-items-center'>
                            {assetsData.owner ? (
                              <>
                                <div className='symbol symbol-35px me-4'>
                                  <span
                                    className='symbol-label fs-7 fw-bold'
                                    style={{backgroundColor: '#DAF9EF'}}
                                  >
                                    {assetsData.owner
                                      ? (() => {
                                          const nameParts = assetsData.owner.split(' ')
                                          if (nameParts.length > 1) {
                                            return `${nameParts[0]
                                              .charAt(0)
                                              .toUpperCase()}${nameParts[1]
                                              .charAt(0)
                                              .toUpperCase()}`
                                          } else {
                                            return `${nameParts[0]
                                              .charAt(0)
                                              .toUpperCase()}${nameParts[0]
                                              .charAt(1)
                                              .toUpperCase()}`
                                          }
                                        })()
                                      : ''}
                                  </span>
                                </div>
                                <div className='d-flex justify-content-start flex-column'>
                                  <a href='#' className='text-dark fw-bold fs-7'>
                                    {assetsData.owner}
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>
                                <span>--</span>
                              </>
                            )}
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <span className='tme-2 fs-6 fw-semibold'>
                                {assetsData.last_checkedin ? formatTimestamp(assetsData.last_checkedin) : "--"}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <div className='clearfix bshadow0 pbs'>
                                {assetsData?.hd_encryption === 'Yes' ? (
                                  <span
                                    className='ki-duotone ki-check-circle text-success fs-2'
                                    style={{marginLeft: 20}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                ) : (
                                  <span
                                    className='ki-duotone ki-cross-circle fs-2'
                                    style={{marginLeft: 20}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <div className='clearfix bshadow0 pbs'>
                                {assetsData?.anti_virus === 'Yes' ? (
                                  <span
                                    className='ki-duotone ki-check-circle text-success fs-2'
                                    style={{marginLeft: 10}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                ) : (
                                  <span
                                    className='ki-duotone ki-cross-circle fs-2'
                                    style={{marginLeft: 10}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <div className='clearfix bshadow0 pbs'>
                                {assetsData?.password_policy === 'Yes' ? (
                                  <span
                                    className='ki-duotone ki-check-circle text-success fs-2'
                                    style={{marginLeft: 20}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                ) : (
                                  <span
                                    className='ki-duotone ki-cross-circle fs-2'
                                    style={{marginLeft: 20}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <div className='clearfix bshadow0 pbs'>
                                {assetsData?.screen_policy === 'Yes' ? (
                                  <span
                                    className='ki-duotone ki-check-circle text-success fs-2'
                                    style={{marginLeft: 20}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                ) : (
                                  <span
                                    className='ki-duotone ki-cross-circle fs-2'
                                    style={{marginLeft: 20}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <div className='clearfix bshadow0 pbs'>
                                {assetsData?.firewall === 'Yes' ? (
                                  <span
                                    className='ki-duotone ki-check-circle text-success fs-2'
                                    style={{marginLeft: 20}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                ) : (
                                  <span
                                    className='ki-duotone ki-cross-circle fs-2'
                                    style={{marginLeft: 20}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className='d-flex flex-column w-100 me-2'>
                            <div className='d-flex flex-stack mb-2'>
                              <div className='clearfix bshadow0 pbs'>
                                {assetsData?.audit_scope === 'Yes' ? (
                                  <span
                                    className='ki-duotone ki-check-circle text-success fs-2'
                                    style={{marginLeft: 20}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                ) : (
                                  <span
                                    className='ki-duotone ki-cross-circle fs-2'
                                    style={{marginLeft: 20}}
                                  >
                                    <span className='path1'></span>
                                    <span className='path2'></span>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        </td>

                        <td>
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                            onClick={() => handleOpenModal2(assetsData.id)}
                            data-bs-target='#kt_modal_2'
                          >
                            <KTIcon iconName='pencil' className='fs-3' />
                          </button>
                          <button
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
                            onClick={(e) => deleteHandler(assetsData)}
                          >
                            <KTIcon iconName='trash' className='fs-3 text-danger' />
                          </button>
                          {/* Delete Confirmation Popup */}
                          {/* {showConfirmation && (
                            <div className='position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center'>
                              <div className='alert alert-dismissible bg-light  d-flex flex-center flex-column py-10 px-10 px-lg-20 mb-10'>
                                <button
                                  type='button'
                                  className='position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-danger'
                                  data-bs-dismiss='alert'
                                  onClick={cancelDelete}
                                ></button>
                                

                                <i className='ki-duotone ki-information-5 fs-5tx text-danger mb-5'>
                                  <span className='path1'></span>
                                  <span className='path2'></span>
                                  <span className='path3'></span>
                                </i>

                                <div className='text-center'>
                               

                                  <div className='mb-9'>
                                    Are you sure you want to delete this asset?
                                  </div>
                                  <div className='d-flex flex-center flex-wrap'>
                                    <a
                                      href='#'
                                      className='btn btn-danger m-2'
                                      onClick={confirmDelete}
                                    >
                                      Yes,Delete
                                    </a>
                                    <a
                                      href='#'
                                      className='btn btn-outline btn-outline-danger btn-active-danger m-2'
                                      onClick={cancelDelete}
                                    >
                                      No,Cancel
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )} */}
                        </td>
                      </tr>
                    </tbody>
                  )
                })
            ) : (
              <tbody>
                <tr style={{height: '100%'}}>
                  {searchLength ? (
                    <td colSpan={10}>
                      <div
                        className='d-flex justify-content-center align-items-center text-center'
                        style={{height: '100%'}}
                      >
                        <div className='m-20'>
                          <img src={toAbsoluteUrl('/media/buttons/emptystate.svg')} alt='' />
                          <div className='m-5 fs-5'>
                            <p style={{fontWeight: 'bold'}}>No Assets Found</p>
                          </div>
                          <p>
                            We couldn't find any assets that match your search. Maybe try a
                            different search!
                          </p>
                        </div>
                      </div>
                    </td>
                  ) : (
                    <td colSpan={10}>
                      <div
                        className='d-flex justify-content-center align-items-center text-center'
                        style={{height: '100%'}}
                      >
                        {!showEmptyState ? (
                          <AssetListLoading />
                        ) : (
                          <td colSpan={10}>
                            <div
                              className='d-flex justify-content-center align-items-center text-center'
                              style={{height: '100%'}}
                            >
                              <div className='m-20'>
                                <img src={toAbsoluteUrl('/media/buttons/emptystate.svg')} alt='' />
                                <div className='m-5 fs-5'>
                                  <p style={{fontWeight: 'bold'}}>No Assets Added</p>
                                </div>
                                <p>
                                  Add the assets that all employees within your organization are
                                  required to adhere to.
                                </p>
                              </div>
                            </div>
                          </td>
                        )}
                      </div>
                    </td>
                  )}
                </tr>
              </tbody>
            )}

            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </>
  )
}

export {AssetManagement}
